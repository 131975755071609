.container {
  .line {
    width: 100%;
    position: relative;
    overflow: hidden;

    span {
      position: absolute;
      color: white;
    }
  }
}

.line {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.marquee {
  position: relative;
  /* width: 100vw; */
  /* max-width: 100%; */
  /* height: 50px; */
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee  linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-75%);
  }
}


.react-multiple-carousel__arrow--left
 {
    left: 0px !important;
    margin-left: 25px;
    z-index: 0 !important;
}

.react-multiple-carousel__arrow--right
 {
    right: 0px !important;
    margin-right: 25px;
    z-index: 0 !important;
}

.button-hover {
  background: linear-gradient(to right, #9900FF 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .2s ease-out;
}

.button-hover:hover {
  background-position: left bottom;
}

